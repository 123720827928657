<template>
  <div class="my-6 text-start">
    <h3 class="font-bold">Criar uma nova Venda </h3>
    <span class="text-sm text-gray-500">Preencha as informações abaixo e clique no botão <b>criar</b> para salvar
      as alterações.
    </span>
  </div>

  <div class="card shadow-sm border p-8 bg-white">
    <div class="w-full">
      <div class="flex-3 w-full mb-7">
        <h3 class="font-bold">Informações da data</h3>
        <span class="text-sm text-gray-500">
          Aqui você pode observar todos os dados relacionados a
          data.
        </span>
      </div>
    </div>

    <div class="flex lg:flex-row flex-col mt-5">
      <div class="w-full p-2">
        <Input type="default" v-model="data.nome" label="Nome" placeholder="Nome" />
      </div>
      <div class="w-full p-2">
        <Input type="cpf" v-model="data.cpf" label="CPF" placeholder="CPF" />
      </div>
      <div class="w-full p-2">
        <Input type="phone" v-model="data.telefone" label="Telefone" placeholder="Telefone" />
      </div>
      <div class="w-full p-2">
        <Input type="default" v-model="data.email" label="Email" placeholder="Email" />
      </div>
    </div>
    <div class="flex lg:flex-row flex-col mt-5">
      <div class="w-full p-2">
        <Input type="default" v-model="data.endereco" label="Endereço" placeholder="Endereço" />
      </div>
      <div class="w-full p-2">
        <Input type="cep" v-model="data.cep" label="Cep" placeholder="Cep" />
      </div>
      <div class="w-full p-2">
        <Input type="default" v-model="data.bairro" label="Bairro" placeholder="Bairro" />
      </div>
    </div>
    <div class="flex lg:flex-row flex-col mt-5">
      <div class="w-full p-2">
        <Input type="default" v-model="data.cidade" label="Cidade" placeholder="Cidade" />
      </div>
      <div class="w-full p-2">
        <Input type="default" v-model="data.estado" label="Estado" placeholder="Estado" />
      </div>
      <div class="w-full p-2">
        <Input type="default" v-model="data.complemento" label="Complemento" placeholder="Complemento" />
      </div>
    </div>
    <div class="flex lg:flex-row flex-col mt-5">
      <div class="w-full p-2">
        <Input type="default" v-model="data.pedido" label="Pedido" placeholder="Pedido" />
      </div>
      <div class="w-full p-2">
        <Input type="default" v-model="data.transacao" label="Transacao" placeholder="Transacao" />
      </div>

      <div class="w-full p-2">
        <Input type="money" v-model="data.valor_loja" label="Valor Loja" placeholder="Valor Loja" />
      </div>
      <div class="w-full p-2">
        <Input type="money" v-model="data.valor_original" label="Valor Original" placeholder="Valor Original" />
      </div>
    </div>

    <div class="flex lg:flex-row flex-col mt-5">
      <div class="w-full p-2">
        <Input type="default" v-model="data.meio_pagamento" label="Meio de Pagamento" placeholder="Meio de Pagamento" />
      </div>
      <div class="w-full p-2">
        <Input type="default" v-model="data.parcelas" label="Parcelas da compra" placeholder="Número de parcelas" />
      </div>
      <div class="w-full p-2">
        <Input type="date" v-model="data.data" label="data" />
      </div>
      <div class="w-full p-2">
        <Input type="date" v-model="data.data_aprovacao" label="data de Aprovação" />
      </div>
      <div class="w-full p-2">
        <Input type="datalist" id="gateway" v-model="data.gateway_pagamento" placeholder="GateWay Pagamento"
          label="GateWay Pagamento" :data="gateway" />
      </div>
    </div>
  </div>

  <hr class="my-9" />

  <div class="card shadow-sm border p-8 bg-white">
    <div class="flex flex-row mt-5 items-center">
      <div class="w-full p-2">
        <Input type="datalist" id="evento" v-model="eventoId" label="Evento" placeholder="Selecione um evento"
          :data="eventos" @keyupSearch="getEvent" />
      </div>
      <div class="flex justify-center mt-3">
        <Button color="primary" :text="`Buscar`" @click="getEvent"></Button>
      </div>
    </div>
    <div class="card" v-if="produtos">
      <DataTable :headers="headers" :data="produtos" :options="options" color="primary" />
    </div>
  </div>
  <hr class="my-9" />
  <div class="flex justify-end mt-5 mb-5">
    <Button @click="emptyChart" color="primary" text="Limpar Carrinho"></Button>
  </div>
  <div class="card shadow-sm border  p-8 bg-white max-h-48 flex flex-wrap overflow-y-auto" v-if="carrinhoCompras">
    <div v-for=" item in carrinhoCompras" :key="item" class=" mb-2  mx-5  ">
      <CarrinhoCompras :produto="item" />
    </div>
  </div>
  <div class="flex justify-end mt-5">
    <Button @click="create" color="primary" text="Criar"></Button>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import CarrinhoCompras from '../../components/Elements/CarrinhoCompras.vue';
import DataTable from "../../components/Elements/Datatable.vue";
import { inject, ref, onMounted } from "vue";
import { POST_VENDAS, GET } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRoute, useRouter } from "vue-router";
import { toDate, toMoney, } from "../../services/formater"

export default {
  name: "Criardata",
  components: {
    Input,
    Button,
    DataTable,
    CarrinhoCompras,
  },

  setup() {
    const alertProps = inject("alert");
    const data = ref({
      nome: "",
      cpf: "",
      telefone: "",
      endereco: "",
      cep: "",
      bairro: "",
      cidade: "",
      estado: "",
      complemento: "",
      pedido: "",
      transacao: "",
      data: "",
      "valor_loja": '',
      "valor_original": '',
      "meio_pagamento": "",
      parcelas: "",
      "data_aprovacao": "",
      "status_pedido": "1",
      "status_entrega": "1",
      "gateway_pagamento": ""
    });

    const content = ref("");
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();
    const produtos = ref([]);
    const eventos = ref([]);
    const eventoId = ref('');
    const carrinhoCompras = ref([]);
    const gateway = ref([])


    const addCarrinho = (param) => {

      const objTemp = { ...param }

      const aux = carrinhoCompras.value;
      data.value["valor_original"] = 0;
      objTemp["qtd_carrinho"] = 1;

      if (aux.length == 0) {
        aux.push(objTemp)
      } else {
        const index = aux.findIndex(el => el.id === objTemp.id);

        if (index < 0) {
          aux.push(objTemp)
        } else {
          aux[index]["qtd_carrinho"] = aux[index]["qtd_carrinho"] + 1;
        }
      }


      aux.map(element => {
        data.value["valor_original"] = parseFloat(data.value["valor_original"] + (element.valor * element.qtd_carrinho))
      })
      data.value["valor_original"] = toMoney(data.value["valor_original"])


      carrinhoCompras.value = aux;
    }
    const headers = [
      { key: "Id", value: "id" },
      { key: "Detalhes", value: "detalhes" },
      { key: "Produto", value: "nome_Produto" },
      { key: "Subtipo", value: "nome_subProdudo" },
      { key: "Preço", value: "valor", money: true },
      { key: "Quantidade", value: "qnt" },
      {
        key: "Options",
        value: 1,
        action: (param) => addCarrinho(param),
        carrinho: true,
        title: "Adicionar"
      },
    ];


    onMounted(async () => {
      loader.open();
      try {
        const response = await GET("event");
        response.map(evento => eventos.value.push({ label: evento.nome + " - " + toDate(evento.data), value: evento.id }));
        const gateaux = await GET("gateway");

        gateaux.map(gate => gateway.value.push({ label: gate.nome, value: gate.id }))

      } catch (e) {
        alert.open("Erro", e, "danger");

      }
      loader.close();
    })



    const create = async () => {
      try {

        if (data.value["data_aprovacao"] == null || data.value["data_aprovacao"] == '') {
          delete data.value["data_aprovacao"]
        }

        if (data.value["complemento"] == null || data.value["complemento"] == '') {
          data.value["complemento"] = ' '
        }

        const validate = isEmpty(data.value);
        if (validate || carrinhoCompras.value.length <= 0) {
          alert.open("Atenção!", validate, "warning");
        } else {
          loader.open();

          const valorFloat = data.value['valor_loja'].split(" ", 2);
          if (valorFloat[1].indexOf(".") != -1) {
            valorFloat[1] = valorFloat[1].replace(".", "");
            data.value["valor_loja"] = valorFloat[1].replace(",", ".");
          } else {
            data.value["valor_loja"] = valorFloat[1].replace(",", ".");
          }

          let valorFloat2 = data.value['valor_original'].split("R$", 2);
          valorFloat2 = valorFloat2[1].replace(/\s/g, '');

          if (valorFloat2.indexOf(".") != -1) {

            valorFloat2 = valorFloat2.replace(".", "");
            data.value['valor_original'] = valorFloat2.replace(",", ".");

          } else {
            data.value['valor_original'] = valorFloat2.replace(",", ".");
          }

          const [gateselected] = gateway.value.filter(gate => gate.label == data.value["gateway_pagamento"]);
          const aux = {
            ...data.value,
            "id_gateway_pagamento": gateselected.value
          }

          delete aux["gateway_pagamento"];


          const carrinho  = [];

          carrinhoCompras.value.map( elemento =>{

            const aux = {
              "codigo_produto" :elemento.codigo_produto,
              "qtd": elemento.qtd_carrinho
            }

            carrinho.push(aux);
          })
          

          const body = {
            venda: aux,
            carrinho: carrinho
          }

          console.log(body)

          try {
            await POST_VENDAS("integracao_vendas/magento", body);
            alert.open(
              "Sucesso!",
              `data criada com sucesso!`,
              "success"
            );
            loader.close();
            router.push("/vendas");
          } catch (e) {
            alert.open("Atenção!", e, "danger");
            loader.close();
          }
        }
      } catch (error) {
        alert.open("Atenção!", error, "danger");
        loader.close();
      }
    };


    const getEvent = async () => {
      loader.open();

      try {
        const [eventoSelecionado] = eventos.value.filter(
          (evento) => evento.label == eventoId.value
        );

        eventoId.value = eventoSelecionado.value;
        const temporario = await GET(`produto/${eventoId.value}/evento/detalhes`);

        temporario.map(prod => {
          prod.valor = (prod.tipo_produto == 102) ? prod.valor_referencia : prod.valor;

          //prod.valor = prod.valor * 100
        })
        produtos.value = temporario;


        if (data.value.length <= 0)
          alert.open("Atenção!", `Não há produtos relacionados a esse evento!`, "warning");

        eventoId.value = eventoSelecionado.label;


      } catch (e) {

        alert.open("Erro", "Um erro ocorreu, tente novamente", "danger");

      }
      loader.close();
    }

    const emptyChart = async () => {
      carrinhoCompras.value.length = 0;
      data.value["valor_original"] = toMoney(0);

    }


    return {
      alertProps,
      data,
      create,
      route,
      content,
      eventos,
      eventoId,
      getEvent,
      produtos,
      headers,
      carrinhoCompras,
      emptyChart,
      gateway
    };
  },
};
</script>

<style></style>
<template>
  <div class="card bg-gray-50 shadow-sm border  border-black border-rounded w-80 h-28 rounded-lg ">

        <div class="flex justify-center mt-2">
            <span  class="font-bold " >{{ produto.detalhes}}</span>
        </div>
        <div class="flex justify-center my-1">
         <span class=" " >Quantidade: {{produto["qtd_carrinho"]}}</span> 
        </div>
        <div class="flex justify-center font-medium  "> 
            <span class="">{{ Valor }}</span>
        </div>

  </div>
  
</template>

<script>
import {toMoney} from '../../services/formater'
export default {
    nome: 'CarrinhoCompras',
    props:{
        produto:{
            type :  Object
        }
    },
    computed: {
        Valor(){
            //console.log(this.produto)
            const valor = this.produto.valor * this.produto["qtd_carrinho"]
            return toMoney(valor)
        }
    }

}
</script>

<style>
.cardCarrinho{
    display: flex;
    align-content:center ;
    align-items: center;
}
</style>